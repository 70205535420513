@tailwind base;
@tailwind components;
@tailwind utilities;
nav {
  background-color: #ffffff; /* White background */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
