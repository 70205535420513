@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --space-2xl: 40px;
  --space-md: 20px;
}
.section-padding {
  padding: var(--space-2xl) var(--space-md);
}
